import { useEffect, useState } from "react";

import DatePicker from "../UI/DatePicker";

import styles from "./SaleTimeFilter.module.scss";
import ToggleButton from "../UI/Buttons/ToggleButton";

function SaleTimeFilter({ selectedSaleTime, onSelectSaleTime }) {
  const [startTime, setStartTime] = useState(selectedSaleTime.startTime);
  const [endTime, setEndTime] = useState(selectedSaleTime.endTime);

  useEffect(() => {
    setStartTime(selectedSaleTime.startTime);
    setEndTime(selectedSaleTime.endTime);
  }, [selectedSaleTime]);

  const { isAllDates, isAllDay } = selectedSaleTime;

  const checkHandler = (mode) => {
    if (mode === "all-dates") {
      onSelectSaleTime({
        ...selectedSaleTime,
        isAllDates: !isAllDates,
      });
      return;
    }
    if (mode === "all-day") {
      onSelectSaleTime({ ...selectedSaleTime, isAllDay: !isAllDay });
    }
  };

  const changeTimeHandler = (timeType, time) => {
    timeType === "startTime" ? setStartTime(time) : setEndTime(time);
    onSelectSaleTime({
      ...selectedSaleTime,
      isAllDay: false,
      [timeType]: time,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles["date-picker-group"]}>
        <ToggleButton
          label="All Dates"
          isChecked={isAllDates}
          onCheck={() => checkHandler("all-dates")}
        />
        <div>
          <DatePicker
            date={startTime}
            onChangeDate={(startTime) =>
              changeTimeHandler("startTime", startTime)
            }
            isDisabled={isAllDates}
            options={{ disabled: isAllDates }}
          />
        </div>
        <div>
          <DatePicker
            date={endTime}
            onChangeDate={(endTime) => changeTimeHandler("endTime", endTime)}
            isDisabled={isAllDates}
            options={{ disabled: isAllDates }}
          />
        </div>
      </div>

      <div className={styles["date-picker-group"]}>
        <ToggleButton
          label="All Day"
          isChecked={isAllDay}
          onCheck={() => checkHandler("all-day")}
        />
        <div>
          <DatePicker
            date={startTime}
            onChangeDate={(startTime) =>
              changeTimeHandler("startTime", startTime)
            }
            isDisabled={isAllDay}
            isTimeOnly={true}
            options={{
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 15,
              timeCaption: "Time",
              dateFormat: "h:mm aa",
              disabled: isAllDay,
            }}
          />
        </div>
        <div>
          <DatePicker
            date={endTime}
            onChangeDate={(endTime) => changeTimeHandler("endTime", endTime)}
            isDisabled={isAllDay}
            isTimeOnly={true}
            options={{
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 15,
              timeCaption: "Time",
              dateFormat: "h:mm aa",
              disabled: isAllDay,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SaleTimeFilter;
