import styles from "./RootLayout.module.scss";

import { Outlet } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Header from "./Header";

export default function RootLayout() {
  return (
    <>
      <Header />
      <div className={styles["content-wrapper"]}>
        <aside className={styles["desktop-nav"]}>
          <NavBar />
        </aside>
        <main className={styles.outlet}>
          <Outlet />
        </main>
      </div>
    </>
  );
}
