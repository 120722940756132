import styles from "./SearchBox.module.scss";
import { Button as ButtonBS, Form, InputGroup } from "react-bootstrap";

import { FaSearch } from "react-icons/fa";

export default function SearchBox({ searchTerm, setSearchTerm }) {
  return (
    <InputGroup className={styles["input-group"]}>
      <Form.Control
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ButtonBS
        variant="primary"
        id="button-addon2"
        className={styles["search-button"]}
      >
        <FaSearch />
      </ButtonBS>
    </InputGroup>
  );
}
