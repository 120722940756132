import { useState } from "react";
import styles from "./InviteForm.module.scss";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { RiSendPlaneFill } from "react-icons/ri";
import Button from "../../UI/Buttons/Button";

function InviteForm() {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Can Edit");

  const submitHandler = (e) => {
    e.preventDefault();

    // TODO: Send invitation email
    console.log(`Inviting ${email} with role ${role}`);

    setEmail("");
    setRole("Can Edit");
  };

  return (
    <form className={styles["invite-form"]}>
      <label htmlFor="inviteEmail" className={styles["invite-label"]}>
        INVITE BY EMAIL
      </label>
      <div className={styles["invite-input-group"]}>
        <InputGroup className={styles["select-wrapper"]}>
          <Form.Control
            id="inviteEmail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <DropdownButton
            variant="light"
            title={role}
            id="input-group-dropdown-2"
            align="end"
          >
            <Dropdown.Item onClick={() => setRole("Can Edit")}>
              Can Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setRole("Can Read")}>
              Can Read
            </Dropdown.Item>
          </DropdownButton>
        </InputGroup>

        <Button
          variant="primary"
          className={styles["invite-button"]}
          onClick={submitHandler}
        >
          <RiSendPlaneFill className={styles["send-icon"]} /> Send Invite
        </Button>
      </div>
    </form>
  );
}

export default InviteForm;
