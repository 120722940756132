import { Link, useLocation } from "react-router-dom";
import styles from "./NavItem.module.scss";

export default function NavItem({ title, path, onClose }) {
  const { pathname } = useLocation();
  return (
    <div className={styles["accordion-item"]}>
      <Link to={`/${path}`} onClick={onClose}>
        <div
          className={`${
            pathname.startsWith(`/${path}`) ? styles["active-section"] : ""
          } ${styles["accordion-title"]}`}
        >
          {title}
        </div>
      </Link>
    </div>
  );
}
