import styles from "./EditableCard.module.scss";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";

export default function EditableCard({
  children,
  title,
  isEditing,
  onEdit,
  onSubmit,
  className,
}) {
  return (
    <div className={`${styles["card-container"]} ${className}`}>
      {title && <p className={styles["card-title"]}>{title}</p>}
      <div className={styles.controls}>
        {isEditing ? (
          <div className={styles["control-btn-group"]}>
            <FaCheck className={styles["control-btn"]} onClick={onSubmit} />
            <FaXmark className={styles["control-btn"]} onClick={onEdit} />
          </div>
        ) : (
          <MdEdit className={styles["control-btn"]} onClick={onEdit} />
        )}
      </div>

      {children}
    </div>
  );
}
