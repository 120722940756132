import { useRef, useState } from "react";
import EditableCard from "../../../UI/EditableCard";

import styles from "./IconContentsEdit.module.scss";

export default function IconContentsEdit({ category, content, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);

  const { subtitle, description } = content;

  const subtitleRef = useRef();
  const descriptionRef = useRef();

  const submitHandler = () => {
    const enteredSubtitle = subtitleRef.current.value;
    const enteredDescription = descriptionRef.current.value;

    // TODO: Send data to API
    onUpdate((prev) => ({
      ...prev,
      ICONS: {
        ...prev.ICONS,
        [category]: {
          ...prev.ICONS[category],
          subtitle: enteredSubtitle,
          description: enteredDescription,
        },
      },
    }));

    setIsEditing(false);
  };

  return (
    <EditableCard
      isEditing={isEditing}
      onEdit={() => setIsEditing((prev) => !prev)}
      onSubmit={submitHandler}
      className={styles["icon-editable-card"]}
    >
      {isEditing ? (
        <input
          id={`icons-${category}-subtitle"`}
          type="text"
          defaultValue={subtitle}
          ref={subtitleRef}
        />
      ) : (
        <p className={styles.subtitle}>{subtitle}</p>
      )}
      {isEditing ? (
        <textarea
          id={`icons-${category}-description"`}
          type="text"
          defaultValue={description}
          ref={descriptionRef}
          rows={3}
        ></textarea>
      ) : (
        <p className={styles.description}>{description}</p>
      )}
    </EditableCard>
  );
}
