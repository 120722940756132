import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./components/UI/Layout/RootLayout";
import Home from "./pages/ContentManagement/Home";
import AboutUsPage from "./pages/ContentManagement/AboutUs";
import FaqPage from "./pages/ContentManagement/Faq";
import TermsAgreementPage from "./pages/ContentManagement/TermsAgreement";
import AccessToAccountsPage from "./pages/PromoterAccountsManagement/AccessToAccounts";
import SendNotificationsPage from "./pages/PromoterAccountsManagement/SendNotifications";
import PromoterApprovalPage from "./pages/PromoterApproval/PromoterApproval";
import MarketingMaterialManagementPage from "./pages/MarketingMaterialManagement/MarketingMaterialManagement";
import StripeAccountPage from "./pages/SystemSetting/StripeAccount";
import VenueStaffManagementPage from "./pages/SystemSetting/VenueStaffManagement";
import StaffManagementPage from "./pages/SystemSetting/StaffManagement";
import ReportsPage from "./pages/Reports/Reports";
import GeneralFaqPage from "./pages/GeneralFaq/GeneralFaq";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "content-management",
        children: [
          { index: true, element: <Home /> },
          { path: "home", element: <Home /> },
          { path: "about-us", element: <AboutUsPage /> },
          { path: "faq", element: <FaqPage /> },
          { path: "terms-and-agreement", element: <TermsAgreementPage /> },
        ],
      },
      {
        path: "promoter-accounts-management",
        children: [
          { path: "access-to-accounts", element: <AccessToAccountsPage /> },
          { path: "send-notifications", element: <SendNotificationsPage /> },
        ],
      },
      {
        path: "promoter-approval",
        element: <PromoterApprovalPage />,
      },
      { path: "reports", element: <ReportsPage /> },
      {
        path: "system-setting",
        children: [
          { path: "staff-management", element: <StaffManagementPage /> },
          {
            path: "venue-staff-management",
            element: <VenueStaffManagementPage />,
          },
          { path: "stripe-account", element: <StripeAccountPage /> },
        ],
      },
      {
        path: "marketing-material-management",
        element: <MarketingMaterialManagementPage />,
      },
      {
        path: "faq",
        element: <GeneralFaqPage />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
