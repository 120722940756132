import Button from "../../components/UI/Buttons/Button";
import SectionWrapper from "../../components/UI/Layout/SectionWrapper";

export default function StripeAccountPage() {
  return (
    <SectionWrapper
      title="Stripe Account"
      description="You can have access to Stripe account here."
    >
      <Button>Access to Stripe Account</Button>
    </SectionWrapper>
  );
}
