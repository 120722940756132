import styles from "./SearchSort.module.scss";
import { Dropdown } from "react-bootstrap";

import SearchBox from "./SearchBox";

const SearchSort = ({
  sortOption,
  setSortOption,
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <div className={styles["search-sort"]}>
      <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <Dropdown>
        <Dropdown.Toggle
          variant="white"
          id="dropdown-basic"
          className={styles["dropdown-toggle"]}
        >
          Sort by: {sortOption}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setSortOption("A-Z")}>
            A-Z
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSortOption("Z-A")}>
            Z-A
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SearchSort;
