import styles from "./EditableContent.module.scss";

function EditableContent({
  inputId,
  textareaId,
  inputLabel,
  inputPlaceholder,
  textareaLabel,
  title,
  body,
  onChangeTitle,
  onChangeBody,
  textareaClassName,
}) {
  return (
    <div className={styles["editable-content"]}>
      {title !== null && (
        <>
          <label htmlFor={inputId} className={styles["input-label"]}>
            {inputLabel}
          </label>
          <input
            id={inputId}
            type="text"
            placeholder={inputPlaceholder}
            value={title}
            onChange={(e) => onChangeTitle(e.target.value)}
          />
        </>
      )}

      {body !== null && (
        <>
          <label htmlFor={textareaId} className={styles["textarea-label"]}>
            {textareaLabel}
          </label>
          <textarea
            id={textareaId}
            className={textareaClassName}
            placeholder="Lorem ipsum dolor sit amet consectetur. Ipsum nullam mi quisque consequat viverra convallis eget viverra arcu. Blandit aliquam nullam pellentesque facilisis. Tincidunt bibendum dui facilisis aliquam eget suspendisse neque amet ut. Sed tortor eget ultrices eget nullam."
            value={body}
            onChange={(e) => onChangeBody(e.target.value)}
          />
        </>
      )}
    </div>
  );
}

export default EditableContent;
