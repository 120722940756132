import { useEffect, useState } from "react";
import useDebounce from "../../../hooks/useDebounce";

import AutoCompleteSuggestionItem from "./AutoCompleteSuggestionItem";
import AutoCompleteSelectedItem from "./AutoCompleteSelectedItem";

import styles from "./AutoCompleteSearch.module.scss";
import { FaSearch } from "react-icons/fa";

export default function AutoCompleteSearch({
  label,
  selectedItems,
  placeholder,
  onFilter,
  onAdd,
  onRemove,
  className,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const filteredList = onFilter(debouncedSearchTerm, selectedItems);

    setFilteredList(filteredList);
  }, [onFilter, debouncedSearchTerm, selectedItems]);

  const selectHandler = (suggestion) => {
    onAdd(suggestion);
    setSearchTerm("");
  };

  return (
    <div className={`${styles["search-container"]} ${className}`}>
      <div className={styles["search-input-container"]}>
        <label
          htmlFor={`auto-complete-search-${label}`}
          className={styles["search-label"]}
        >
          {label}
        </label>

        <div className={styles["search-input-group"]}>
          <FaSearch size={24} />
          <input
            id={`auto-complete-search-${label}`}
            type="text"
            placeholder={placeholder}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles["search-input"]}
          />
        </div>
        {searchTerm && (
          <>
            <div className={styles.blank}></div>
            <ul className={styles.suggestions}>
              {filteredList.length > 0
                ? filteredList.map((suggestion, i) => (
                    <AutoCompleteSuggestionItem
                      key={i}
                      suggestion={suggestion}
                      displayText={
                        suggestion.name ? suggestion.name : suggestion
                      }
                      searchTerm={searchTerm}
                      onSelect={selectHandler.bind(null, suggestion)}
                    />
                  ))
                : "No Results Found"}
            </ul>
          </>
        )}
      </div>
      <div className={styles["selected-items"]}>
        {selectedItems.map((item, i) => (
          <AutoCompleteSelectedItem
            key={i}
            item={item.name ? item.name : item}
            onRemoveItem={() => onRemove(item)}
          />
        ))}
      </div>
    </div>
  );
}
