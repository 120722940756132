import { useState } from "react";

import { DUMMY_STAFF_LIST } from "../../DUMMY_DATA/DUMMY_STAFF";

import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import InviteForm from "../../components/SystemSetting/StaffManagement/InviteForm";
import MembersList from "../../components/SystemSetting/StaffManagement/MembersList";
import ActionButtons from "../../components/UI/Buttons/ActionButtons";

import styles from "./StaffManagement.module.scss";

export default function StaffManagementPage() {
  const [members, setMembers] = useState(DUMMY_STAFF_LIST);

  const submitHandler = () => {
    console.log(members);
    // TODO: Send data to API
  };

  const actionButtons = [
    {
      text: "Update",
      variant: "primary",
      onClick: submitHandler,
      className: styles["action-button"],
    },
  ];

  return (
    <SectionWrapper
      title="Staff Management"
      description="You can manage team access here."
      className={styles["section-wrapper"]}
    >
      <InviteForm />
      <MembersList members={members} onUpdate={setMembers} />
      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
    </SectionWrapper>
  );
}
