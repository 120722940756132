export const dataOptions = [
  { oid: "d1", title: "Promoters", description: "" },
  { oid: "d2", title: "Venues", description: "" },
  { oid: "d3", title: "Patrons", description: "" },
  { oid: "d4", title: "Influencers", description: "" },
  { oid: "d5", title: "Ticket Data", description: "" },
];

export const marketingOptions = [
  { oid: "m1", title: "Heard about us", description: "" },
  { oid: "m2", title: "Patron referrals", description: "" },
  {
    oid: "m3",
    title: "Sales made by city/zip code/postal code",
    description: "",
  },
  { oid: "m4", title: "Event sales by day progress report", description: "" },
  {
    oid: "m5",
    title: "Coupon codes redeemed report (Promoter Report)",
    description: "",
  },
  {
    oid: "m6",
    title: "Ticketing Trends",
    description:
      "Historical data on ticket sales patterns, including peak buying times, seasonal trends, and pricing impacts. This can help in forecasting and strategic planning.",
  },
  {
    oid: "m7",
    title: "Event Performance Reports",
    description:
      "Evaluation of individual event performance, including comparison with past events, attendee feedback, and overall success metrics.",
  },
  { oid: "m8", title: "Top Selling Events", description: "" },
];

export const scanOptions = [
  {
    oid: "s1",
    title: "Device Status Report",
    description:
      "Real-time activity of scans, showing individual device and overall status.",
  },
  {
    oid: "s2",
    title: "Scanner Comments made by user at a specific performance.",
    description:
      "Comments made by users at specific performances, including date, time, and resolution.",
  },
  {
    oid: "s3",
    title: "Re-entry Scans",
    description:
      "Lists patrons with re-entries, filtered by performance and device.",
  },
  {
    oid: "s4",
    title: "Scanned In Report",
    description: "Details of patrons who were scanned into the event.",
  },
  {
    oid: "s5",
    title: "Scanned Out Inventory",
    description: "Information on who scanned out during the event.",
  },
  {
    oid: "s6",
    title: "No Show Report",
    description: "Report on patrons who never scanned in.",
  },
  {
    oid: "s7",
    title: "Section Scanned",
    description: "Breakdown of scanned sections within the venue.",
  },
  {
    oid: "s8",
    title: "Scan by Price",
    description:
      "Displays the quantity and percentage of sold tickets by price category, scanned within the selected time range.",
  },
];

export const accountingOptions = [
  {
    oid: "a1",
    title: "Reconciliation",
    description: "Covers ticket/event sales, payment methods, and refunds.",
  },
  { oid: "a2", title: "Ticket Sales", description: "" },
  {
    oid: "a3",
    title: "Commissions",
    description:
      "Breakdown of commissions earned by promoters from influencers.",
  },
  { oid: "a4", title: " Service Fee", description: "" },
  {
    oid: "a5",
    title: "Refund report",
    description: "Details refunds processed for an event.",
  },
  {
    oid: "a6",
    title: "Ticket Income",
    description:
      "Provides a total breakdown of sales, service fees, commissions, taxes, and included/excluded fees.",
  },
];
