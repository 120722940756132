import { useState } from "react";

import { DUMMY_CM_FAQ } from "../../DUMMY_DATA/DUMMY_CM";

import Button from "../../components/UI/Buttons/Button";
import EditableContent from "../../components/UI/EditableContent";
import SectionWrapper from "../../components/UI/Layout/SectionWrapper";

import styles from "./Faq.module.scss";
import ActionButtons from "../../components/UI/Buttons/ActionButtons";

export default function FaqPage() {
  const [prevData, setPrevData] = useState(DUMMY_CM_FAQ);

  const [questions, setQuestions] = useState(DUMMY_CM_FAQ);

  const addQuestionHandler = () => {
    setQuestions([
      ...questions,
      { qid: Math.random(), question: "", answer: "" },
    ]);
  };

  const removeQuestionHandler = (qid) => {
    if (questions.length > 1) {
      setQuestions((prev) => prev.filter((q) => q.qid !== qid));
    }
  };

  const handleQuestionChange = (qid, newQuestion) => {
    const updatedQuestionIndex = questions.findIndex((q) => q.qid === qid);
    const updatedQuestions = [...questions];
    updatedQuestions[updatedQuestionIndex].question = newQuestion;
    setQuestions(updatedQuestions);
  };

  const handleAnswerChange = (qid, newAnswer) => {
    const updatedQuestionIndex = questions.findIndex((q) => q.qid === qid);
    const updatedQuestions = [...questions];
    updatedQuestions[updatedQuestionIndex].answer = newAnswer;
    setQuestions(updatedQuestions);
  };

  const submitHandler = () => {
    console.log("FAQ:", questions);

    // TODO: Send the extracted data to the API
  };

  const discardHandler = () => {
    setQuestions(prevData);
  };

  const actionButtons = [
    {
      text: "Add Question",
      variant: "secondary",
      onClick: addQuestionHandler,
      className: styles["action-button"],
    },
    {
      text: "Discard",
      variant: "secondary",
      onClick: discardHandler,
      className: styles["action-button"],
    },
    {
      text: "Update",
      variant: "primary",
      onClick: submitHandler,
      className: styles["action-button"],
    },
  ];

  return (
    <>
      <SectionWrapper
        title="FAQ"
        description="You can change the content of the Frequently-Asked-Questions page here."
      >
        <div className={styles["questions-container"]}>
          {questions.map((question, index) => (
            <div key={question.qid}>
              <EditableContent
                qid={question.qid}
                inputId={`faq-question-${question.qid}`}
                inputLabel={`Question ${index + 1}`}
                inputPlaceholder="Is there a fee for using EventLinx?"
                textareaId={`faq-answer-${question.qid}`}
                textareaLabel={`Answer of Question ${index + 1}`}
                textareaClassName={styles.textarea}
                title={question.question}
                body={question.answer}
                onChangeTitle={(newQuestion) =>
                  handleQuestionChange(question.qid, newQuestion)
                }
                onChangeBody={(newAnswer) =>
                  handleAnswerChange(question.qid, newAnswer)
                }
              />
              <Button
                variant="secondary"
                onClick={() => removeQuestionHandler(question.qid)}
              >
                Remove
              </Button>
            </div>
          ))}
        </div>
      </SectionWrapper>

      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
    </>
  );
}
