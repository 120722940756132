import { DUMMY_EVENTS_LIST } from "../DUMMY_DATA/DUMMY_EVENTS";
import { DUMMY_PROMOTERS_LIST } from "../DUMMY_DATA/DUMMY_PROMOTERS";

export const filterByPromoter = (searchTerm, selectedItems) => {
  // TODO: Fetch list from API. Do the filtering at backend according to the route /search?category=promoter&searchTerm=....

  const filteredList = DUMMY_PROMOTERS_LIST.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return filteredList.filter(
    (item) =>
      !selectedItems.some((selectedItem) => selectedItem.pid === item.pid)
  );
};

export const filterByEvent = (searchTerm, selectedItems) => {
  // TODO: Fetch list from API. Do the filtering at backend according to the route /search?category=event&searchTerm=....

  const filteredList = DUMMY_EVENTS_LIST.filter((event) =>
    event.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return filteredList.filter(
    (item) =>
      !selectedItems.some((selectedItem) => selectedItem.eid === item.eid)
  );
};

export const filterByVenue = (searchTerm, selectedItems) => {
  // TODO: Fetch list from API. Do the filtering at backend according to the route /search?category=venue&searchTerm=...

  const venueSet = new Set(
    DUMMY_EVENTS_LIST.filter((event) =>
      event.venue.toLowerCase().includes(searchTerm.toLowerCase())
    ).map((event) => event.venue)
  );

  return [...venueSet].filter((item) => !selectedItems.includes(item));
};

export const filterByPerformance = (searchTerm, selectedItems) => {
  // TODO: Fetch list from API. Do the filtering at backend according to the route /search?category=performance&searchTerm=....
  const performanceSet = new Set(
    DUMMY_EVENTS_LIST.filter((event) =>
      event.performance.toLowerCase().includes(searchTerm.toLowerCase())
    ).map((event) => event.performance)
  );

  return [...performanceSet].filter((item) => !selectedItems.includes(item));
};
