import { useRef, useState } from "react";

import EditableCard from "../../UI/EditableCard";
import SectionWrapper from "../../UI/Layout/SectionWrapper";

import styles from "./Quote.module.scss";

function QuoteSection({ quote, onUpdate }) {
  const [isEditingQuote, setIsEditingQuote] = useState(false);

  const quoteRef = useRef();

  const submitHandler = () => {
    const enteredQuote = quoteRef.current.value;

    console.log(enteredQuote);

    // TODO: Send data to API
    onUpdate((prev) => ({ ...prev, QUOTE: enteredQuote }));

    setIsEditingQuote(false);
  };

  return (
    <SectionWrapper
      title="Quote"
      description="You can change the content of the quote here"
    >
      <EditableCard
        onEdit={() => setIsEditingQuote((prev) => !prev)}
        onSubmit={submitHandler}
        isEditing={isEditingQuote}
        className={styles["quote-editable-card"]}
      >
        {isEditingQuote ? (
          <textarea
            id="quote"
            type="text"
            ref={quoteRef}
            defaultValue={quote}
          ></textarea>
        ) : (
          <p className={styles["quote-text"]}>{quote}</p>
        )}
      </EditableCard>
    </SectionWrapper>
  );
}

export default QuoteSection;
