import styles from "./ToggleButton.module.scss";

export default function ToggleButton({ label, isChecked, onCheck }) {
  return (
    <label className={styles["checkbox-label"]}>
      <div className={styles["toggle-button-container"]}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={isChecked}
          onChange={() => onCheck()}
        />
        <div
          className={`${styles["toggle-button"]} ${
            isChecked ? styles.active : ""
          }`}
        ></div>
      </div>
      <span className={styles["checkbox-text"]}>{label}</span>
    </label>
  );
}
