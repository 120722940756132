import SectionWrapper from "../../components/UI/Layout/SectionWrapper";

export default function GeneralFaqPage() {
  return (
    <SectionWrapper
      title="General FAQ"
      description={"This is the general FAQ page"}
    ></SectionWrapper>
  );
}
