import styles from "./SectionWrapper.module.scss";

export default function SectionWrapper({
  children,
  className,
  title,
  description,
}) {
  return (
    <section className={styles["section-wrapper"]}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <div className={className}>{children}</div>
    </section>
  );
}
