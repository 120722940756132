import Button from "../UI/Buttons/Button";
import styles from "./CategoryFilter.module.scss";

export default function CategoryFilter({ selectedCategory, onSelectCategory }) {
  const categories = [
    "All",
    "Festival",
    "Concert",
    "Theater",
    "Sport",
    "Art",
    "Food",
    "Music",
  ];

  return (
    <div className={styles["category-container"]}>
      {categories.map((category) => (
        <Button
          key={category}
          className={styles["category-button"]}
          variant={selectedCategory.includes(category) ? "primary" : "third"}
          onClick={() => onSelectCategory(category)}
        >
          {category}
        </Button>
      ))}
    </div>
  );
}
