import { useMemo } from "react";

import PromoterItem from "./PromoterItem";

import styles from "./PromoterList.module.scss";
import { Table } from "react-bootstrap";

function PromoterList({ list, sortOption, searchTerm }) {
  let sortedPromoters = useMemo(() => {
    switch (sortOption) {
      case "A-Z":
        return list.sort((a, b) => a.name.localeCompare(b.name));

      case "Z-A":
        return list.sort((a, b) => b.name.localeCompare(a.name));

      default:
        return list;
    }
  }, [list, sortOption]);

  if (searchTerm) {
    sortedPromoters = sortedPromoters.filter((promoter) =>
      promoter.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  }

  return (
    <>
      <Table responsive className={styles["promoter-list"]}>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Access</th>
            <th>Event</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedPromoters.length !== 0 &&
            sortedPromoters.map((promoter) => (
              <PromoterItem key={promoter.pid} promoter={promoter} />
            ))}
          {sortedPromoters.length === 0 && (
            <tr>
              <td colSpan={3}>No promoters found.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default PromoterList;
