import SectionWrapper from "../../components/UI/Layout/SectionWrapper";

export default function MarketingMaterialManagementPage() {
  return (
    <SectionWrapper
      title="Marketing Material Management"
      description="You can manage marketing materials here."
    ></SectionWrapper>
  );
}
