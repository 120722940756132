import { useEffect, useState } from "react";

import MobileNavBar from "../NavBar/MobileNavBar/MobileNavBar";

import styles from "./Header.module.scss";
import { FaRegCircleUser } from "react-icons/fa6";
import { IoMenu } from "react-icons/io5";

export default function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    const closeMenu = () => {
      setShowMobileMenu(false);
    };

    window.addEventListener("resize", closeMenu);
    return () => window.removeEventListener("resize", closeMenu);
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles["logo-container"]}>
        <img
          src="/assets/logo/logo-blue-dark-bg.svg"
          alt="EventLinx"
          className={styles.logo}
        />
      </div>
      {showMobileMenu && (
        <MobileNavBar onClose={() => setShowMobileMenu(false)} />
      )}
      <div className={styles["icon-container"]}>
        <button>
          <FaRegCircleUser />
        </button>
        <button className={styles["menu-btn"]}>
          <IoMenu onClick={() => setShowMobileMenu(true)} />
        </button>
      </div>
    </header>
  );
}
