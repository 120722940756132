import styles from "./MembersList.module.scss";
import { Dropdown } from "react-bootstrap";

function MembersList({ members, onUpdate }) {
  const roleChangeHandler = (sid, newRole) => {
    const updatedMembers = [...members];
    const updatedMemberIndex = updatedMembers.findIndex(
      (member) => member.sid === sid
    );
    updatedMembers[updatedMemberIndex].role = newRole;
    onUpdate(updatedMembers);
  };

  return (
    <div className={styles["members-list"]}>
      <h3 className={styles.title}>Members List</h3>
      <ul>
        {members.map((member) => (
          <li key={member.sid} className={styles["member-item"]}>
            <div className={styles["member-info"]}>
              <img
                src={member.avatar}
                alt=""
                className={styles["member-avatar"]}
              />
              <span>{member.name}</span>
            </div>
            {member.role === "Main Admin" ? (
              <div className={styles["role-admin"]}>Main Admin</div>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className={styles["role-toggle"]}
                >
                  {member.role}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => roleChangeHandler(member.sid, "Can Edit")}
                  >
                    Can Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => roleChangeHandler(member.sid, "Can Read")}
                  >
                    Can Read
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MembersList;
