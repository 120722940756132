import { useState } from "react";

import { DUMMY_EVENTS_LIST } from "../../../../DUMMY_DATA/DUMMY_EVENTS";

import EventList from "./EventList";
import ModalWrapper from "../../../UI/Layout/ModalWrapper";
import SendPromoterNoteForm from "./SendPromoterNoteForm";
import Button from "../../../UI/Buttons/Button";

import styles from "./PromoterItem.module.scss";
import { Modal } from "react-bootstrap";
import { RiSendPlaneFill } from "react-icons/ri";

const ContentWrapper = ({ children, promoterName }) => {
  return (
    <>
      <p className={styles["promoter-label"]}>Promoter name:</p>
      <p className={styles["promoter-name"]}>{promoterName}</p>
      {children}
    </>
  );
};

export default function PromoterItem({ promoter }) {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const changeModalContentHandler = (contentType) => {
    if (contentType === "note") {
      setModalContent(
        <ModalWrapper onClose={() => setShowModal(false)}>
          <ContentWrapper promoterName={promoter.name}>
            <SendPromoterNoteForm
              promoter={promoter}
              onClose={() => setShowModal(false)}
            />
          </ContentWrapper>
        </ModalWrapper>
      );
    }

    if (contentType === "events") {
      const eventList = DUMMY_EVENTS_LIST.filter(
        (event) => event.promoter === promoter.pid
      );

      setModalContent(
        <ModalWrapper onClose={() => setShowModal(false)}>
          <ContentWrapper promoterName={promoter.name}>
            <EventList list={eventList} showPromoterName={false} />
          </ContentWrapper>
        </ModalWrapper>
      );
    }

    setShowModal(true);
  };

  return (
    <>
      <tr key={promoter.pid}>
        <td>{promoter.name}</td>
        <td>
          <Button className={styles["access-button"]}>Access to account</Button>
        </td>
        <td>
          <button
            className={styles["view-events-button"]}
            onClick={() => changeModalContentHandler("events")}
          >
            View Events
          </button>
        </td>
        <td>
          <Button
            variant="secondary"
            onClick={() => changeModalContentHandler("note")}
          >
            <RiSendPlaneFill className={styles["send-icon"]} />
            <span>Send a note</span>
          </Button>
        </td>
      </tr>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        {modalContent}
      </Modal>
    </>
  );
}
