export const DUMMY_PROMOTERS_LIST = [
  {
    name: "John Thomson",
    pid: 1,
    profilePic:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/6ed681615c82f60c5353e19884d8bff2a7554ee832fd2e1424ae62b78d263410?apiKey=d92a299f0cfc4f17bca506c7d54a7408&",
  },
  {
    name: "Chris Navad",
    pid: 2,
    profilePic:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2e19b719681d4c15305e1875eedef686d6d06a01e99f0258e60fa27c1e91ff38?apiKey=d92a299f0cfc4f17bca506c7d54a7408&",
  },
  {
    name: "John Swift",
    pid: 3,
    profilePic:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/64e5dca5a222338fbe3e14e7bbb43a7c740695bddbeadc652ba1ca624f581215?apiKey=d92a299f0cfc4f17bca506c7d54a7408&",
  },
  {
    name: "Taylor Smith",
    pid: 4,
    profilePic:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/9335476c48acc503eac454fab4af15d2fa066f205f2b3a422c23270105a86107?apiKey=d92a299f0cfc4f17bca506c7d54a7408&",
  },
  {
    name: "Joseph Thomson",
    pid: 5,
    profilePic:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/7070e742d4fa176386bc47c89bfdeff1c7672ddc7f1d8d90d305b65be0446c80?apiKey=d92a299f0cfc4f17bca506c7d54a7408&",
  },
];
