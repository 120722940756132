import { useState } from "react";

import Button from "../../../UI/Buttons/Button";
import EditableContent from "../../../UI/EditableContent";

import styles from "./SendPromoterNoteForm.module.scss";
import ActionButtons from "../../../UI/Buttons/ActionButtons";

export default function SendPromoterNoteForm({ promoter }) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const sendHandler = () => {
    console.log({
      pid: promoter.pid,
      title,
      body,
    });

    // TODO: Send data to API
  };

  const discardHandler = () => {
    setTitle("");
    setBody("");
  };

  const actionButtons = [
    {
      text: "Discard",
      variant: "secondary",
      onClick: discardHandler,
      className: styles["action-button"],
    },
    {
      text: "Send",
      variant: "primary",
      onClick: sendHandler,
      className: styles["action-button"],
    },
  ];

  return (
    <>
      <EditableContent
        inputId="notification-title"
        inputLabel="Title"
        inputPlaceholder="Notification Title"
        textareaId="notification-body"
        textareaLabel=""
        textareaClassName={styles.textarea}
        title={title}
        body={body}
        onChangeTitle={setTitle}
        onChangeBody={setBody}
      />
      <p>{500 - body.length} characters remaining.</p>

      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
    </>
  );
}
