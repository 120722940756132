import styles from "./ModalWrapper.module.scss";
import { IoCloseCircle } from "react-icons/io5";

export default function ModalWrapper({ children, onClose }) {
  return (
    <dialog open className={styles.modal}>
      <IoCloseCircle
        className={styles["close-icon"]}
        onClick={onClose}
        size={24}
      />
      {children}
    </dialog>
  );
}
