export const DUMMY_REQUESTS_LIST = [
  {
    requestDate: "02 May, 2024, 01:30 pm",
    rid: 1,
    promoterName: "John Smith",
    status: "Pending",
  },
  {
    requestDate: "02 May, 2024, 01:30 pm",
    rid: 2,
    promoterName: "Chris Navad",
    status: "Approved",
  },
  {
    requestDate: "02 May, 2024, 01:30 pm",
    rid: 3,
    promoterName: "John Swift",
    status: "Rejected",
  },
  {
    requestDate: "02 May, 2024, 01:30 pm",
    rid: 4,
    promoterName: "Taylor Smith",
    status: "Approved",
  },
  {
    requestDate: "02 May, 2024, 01:30 pm",
    rid: 5,
    promoterName: "Joseph Thomson",
    status: "Pending",
  },
];
