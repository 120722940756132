import Button from "./Button";

export default function RadioButtons({
  buttons,
  currentState,
  onChangeValue,
  className,
}) {
  return (
    <div className={className}>
      {buttons.map((button) => (
        <Button
          key={button.value}
          variant={currentState === button.value ? "primary" : "inactive"}
          onClick={() => onChangeValue(button.value)}
        >
          {button.text}
        </Button>
      ))}
    </div>
  );
}
