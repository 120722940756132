import NavBar from "../NavBar";

import styles from "./MobileNavBar.module.scss";
import { IoCloseCircle } from "react-icons/io5";

export default function MobileNavBar({ onClose }) {
  return (
    <div className={styles["mobile-nav-backdrop"]}>
      <aside className={styles["mobile-nav"]}>
        <button className={styles["close-btn"]} onClick={onClose}>
          <IoCloseCircle
            className={styles["close-icon"]}
            onClick={onClose}
            size={24}
          />
        </button>
        <NavBar onClose={onClose} />
      </aside>
    </div>
  );
}
