import { useState } from "react";

import {
  accountingOptions,
  dataOptions,
  marketingOptions,
  scanOptions,
} from "../../data/report-options";

import ReportList from "../../components/Reports/ReportList";
import TabSelector from "../../components/UI/TabSelector";
import SectionWrapper from "../../components/UI/Layout/SectionWrapper";
import ActionButtons from "../../components/UI/Buttons/ActionButtons";

import styles from "./Reports.module.scss";

export default function ReportsPage() {
  const [activeTab, setActiveTab] = useState("Data");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    promoter: [],
    event: [],
    venue: [],
    performance: [],
    category: [],
    selectedDateRange: new Date(),
    saleTime: {
      isAllDates: false,
      isAllDay: false,
      startTime: new Date(),
      endTime: new Date(),
    },
  });

  const addSearchFilterHandler = (searchCategory, filter) => {
    setSelectedFilters((prevReportFilter) => ({
      ...prevReportFilter,
      [searchCategory]: [...prevReportFilter[searchCategory], filter],
    }));
  };

  const removeSearchFilterHandler = (category, filterToRemove) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      newFilters[category] = prevFilters[category].filter((filter) =>
        category === "promoter"
          ? filter.pid !== filterToRemove.pid
          : category === "event"
          ? filter.eid !== filterToRemove.eid
          : filter !== filterToRemove
      );
      return newFilters;
    });
  };

  const selectDateRangeHandler = (dateRange) => {
    setSelectedFilters((prevReportFilter) => ({
      ...prevReportFilter,
      selectedDateRange: dateRange,
    }));
  };

  const selectSaleTimeHandler = (saleTime) => {
    setSelectedFilters((prevReportFilter) => ({
      ...prevReportFilter,
      saleTime: saleTime,
    }));
  };

  const selectCategoryHandler = (category) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      category: prevFilters.category.includes(category)
        ? prevFilters.category.filter((c) => c !== category)
        : [...prevFilters.category, category],
    }));
  };

  const resetFiltersHandler = () => {
    setSelectedFilters({
      promoter: [],
      event: [],
      venue: [],
      performance: [],
      category: [],
      selectedDateRange: new Date(),
      saleTime: {
        isAllDates: false,
        isAllDay: false,
        startTime: new Date(),
        endTime: new Date(),
      },
    });
  };

  const submitHandler = (accessMethod) => {
    accessMethod === "view"
      ? // TODO: Send data to API
        console.log("View report", selectedOptions, selectedFilters)
      : console.log("Download report", selectedOptions, selectedFilters);
  };

  const toggleOptionHandler = (optionId) => {
    setSelectedOptions((prev) =>
      prev.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...prev, optionId]
    );
  };

  let optionList;
  switch (activeTab) {
    case "Data":
      optionList = dataOptions;
      break;
    case "Marketing":
      optionList = marketingOptions;
      break;
    case "Scan":
      optionList = scanOptions;
      break;
    case "Accounting":
      optionList = accountingOptions;
      break;
    default:
      optionList = dataOptions;
  }

  const actionButtons = [
    {
      text: "View Only",
      variant: "secondary",
      onClick: () => submitHandler("view"),
    },
    {
      text: "Download",
      variant: "primary",
      onClick: () => submitHandler("download"),
    },
  ];

  const tabOptions = ["Data", "Marketing", "Scan", "Accounting"];

  console.log(selectedFilters);

  return (
    <SectionWrapper
      title="Reports"
      description="You can view and download reports here."
      className={styles["section-wrapper"]}
    >
      <div>
        <h3 className={styles.subheading}>Select the report you want</h3>

        <TabSelector
          tabOptions={tabOptions}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className={styles["tab-selector"]}
        />

        <ReportList
          optionList={optionList}
          selectedOptions={selectedOptions}
          selectedFilters={selectedFilters}
          onToggleOption={toggleOptionHandler}
          onAddSearchFilter={addSearchFilterHandler}
          onRemoveSearchFilter={removeSearchFilterHandler}
          onSelectCategory={selectCategoryHandler}
          onSelectDateRange={selectDateRangeHandler}
          onSelectSaleTime={selectSaleTimeHandler}
          onResetFilters={resetFiltersHandler}
        />
      </div>
      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons"]}
      />
    </SectionWrapper>
  );
}
