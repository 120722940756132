import Button from "./Button";
import styles from "./ActionButtons.module.scss";

export default function ActionButtons({ buttons, className }) {
  return (
    <div className={`${className} ${styles["action-buttons"]}`}>
      {buttons.map((button, index) => (
        <Button
          key={index}
          variant={button.variant}
          onClick={button.onClick}
          className={button.className}
        >
          {button.text}
        </Button>
      ))}
    </div>
  );
}
