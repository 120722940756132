import SectionWrapper from "../../components/UI/Layout/SectionWrapper";

export default function VenueStaffManagementPage() {
  return (
    <SectionWrapper
      title="Venue Staff Management"
      description="You can manage venue staff here."
    ></SectionWrapper>
  );
}
