import styles from "./TabSelector.module.scss";

const TabSelector = ({ tabOptions, activeTab, setActiveTab, className }) => {
  return (
    <div className={`${styles["tab-selector"]} ${className}`}>
      {tabOptions.map((tab, index) => (
        <button
          key={tab}
          style={{
            zIndex: tabOptions.length - index,
            transform: `translateX(-${index * 30}px)`,
          }}
          className={`${styles.tab} ${
            activeTab === tab ? styles["active-tab"] : ""
          }`}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabSelector;
