import styles from "./CTAEdit.module.scss";

import { useRef, useState } from "react";
import EditableCard from "../../../UI/EditableCard";

export default function CTAEdit({ title, category, content, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);

  const { subtitle, description, cta } = content;

  const subtitleRef = useRef();
  const descriptionRef = useRef();
  const ctaRef = useRef();

  const submitHandler = () => {
    const enteredSubtitle = subtitleRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    const enteredCta = ctaRef.current.value;

    // TODO: Send data to API
    onUpdate((prev) => ({
      ...prev,
      CTA: {
        ...prev.CTA,
        [category]: {
          ...prev.CTA[category],
          subtitle: enteredSubtitle,
          description: enteredDescription,
          cta: enteredCta,
        },
      },
    }));

    setIsEditing(false);
  };

  return (
    <EditableCard
      title={title}
      isEditing={isEditing}
      onEdit={() => setIsEditing((prev) => !prev)}
      onSubmit={submitHandler}
      className={styles["editable-card"]}
    >
      {isEditing ? (
        <input
          id={`cta-${category}-subtitle`}
          type="text"
          defaultValue={subtitle}
          ref={subtitleRef}
        />
      ) : (
        <p className={styles["cta-subtitle"]}>{subtitle}</p>
      )}
      {isEditing ? (
        <input
          id={`cta-${category}-description`}
          type="text"
          defaultValue={description}
          ref={descriptionRef}
        />
      ) : (
        <p className={styles["cta-description"]}>{description}</p>
      )}
      {isEditing ? (
        <input
          id={`cta-${category}-cta`}
          type="text"
          defaultValue={cta}
          ref={ctaRef}
        />
      ) : (
        <p className={styles["cta-cta"]}>{cta}</p>
      )}
    </EditableCard>
  );
}
