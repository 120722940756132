import { IoCloseCircle } from "react-icons/io5";
import styles from "./AutoCompleteSelectedItem.module.scss";

export default function AutoCompleteSelectedItem({ item, onRemoveItem }) {
  return (
    <div className={styles["selected-item"]}>
      <span>{item}</span>
      <IoCloseCircle
        className={styles["close-icon"]}
        onClick={() => onRemoveItem()}
        size={20}
      />
    </div>
  );
}
