export const DUMMY_STAFF_LIST = [
  {
    sid: 1,
    name: "Marvin McKinney",
    avatar:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/23bf186071b360f74ab64815454cc5ae9acf185323d28716ab1959cc948046d9?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
    role: "Main Admin",
  },
  {
    sid: 2,
    name: "Ronald Richards",
    avatar:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/f54d231553bc2a0c1457942ad4b97db569619aef321d036efcb3372ddfcf3d7b?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
    role: "Can Edit",
  },
  {
    sid: 3,
    name: "Cameron Williamson",
    avatar:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/deddc834082076dd4999a31ed1757782e7ffd4d13c732cabf1cfbe030ac33754?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
    role: "Can Edit",
  },
  {
    sid: 4,
    name: "Arlene McCoy",
    avatar:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/a75b0f39406573c7ba7d9e45fe73bb873e4195e5b18135d3c66a8d526774959a?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
    role: "Can Edit",
  },
  {
    sid: 5,
    name: "Jerome Bell",
    avatar:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2f2f896d5f87a87d168f97cd992479e55cc1dbdc78c2693915172c100348f033?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
    role: "Can Edit",
  },
  {
    sid: 6,
    name: "Dianne Russell",
    avatar:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/c331b407368bdb6da1870e895fe00d09cfa1233fef67739c421428d0778f2219?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
    role: "Can Edit",
  },
];
