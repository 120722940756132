import { useMemo } from "react";

import styles from "./EventList.module.scss";
import { Table } from "react-bootstrap";
import Button from "../../../UI/Buttons/Button";
import { DUMMY_PROMOTERS_LIST } from "../../../../DUMMY_DATA/DUMMY_PROMOTERS";

const getPromoterName = (pid) => {
  return DUMMY_PROMOTERS_LIST.find((promoter) => promoter.pid === pid).name;
};

function EventList({ list, showPromoterName, sortOption, searchTerm }) {
  let sortedEvents = useMemo(() => {
    switch (sortOption) {
      case "A-Z":
        return list.sort((a, b) => a.name.localeCompare(b.name));

      case "Z-A":
        return list.sort((a, b) => b.name.localeCompare(a.name));

      default:
        return list;
    }
  }, [list, sortOption]);

  if (searchTerm) {
    sortedEvents = sortedEvents.filter((events) =>
      events.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  }

  return (
    <>
      <Table responsive className={styles["promoter-list"]}>
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Access</th>
            {showPromoterName && <th>Promoter Name</th>}
          </tr>
        </thead>
        <tbody>
          {sortedEvents.length !== 0 &&
            sortedEvents.map((event) => (
              <tr key={event.eid}>
                <td>{event.name}</td>
                <td>
                  <Button className={styles["overwrite-button"]}>
                    Access to Event
                  </Button>
                </td>
                {showPromoterName && <td>{getPromoterName(event.promoter)}</td>}
              </tr>
            ))}
          {sortedEvents.length === 0 && (
            <tr>
              <td colSpan={3}>No events found.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default EventList;
