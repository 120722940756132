import styles from "./AutoCompleteSuggestionItem.module.scss";

export default function AutoCompleteSuggestionItem({
  suggestion,
  displayText,
  searchTerm,
  onSelect,
}) {
  function getHighlightedText(text, searchTerm) {
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <span key={i} className={styles.highlight}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  }

  return (
    <li className={styles["suggestion-item"]} onClick={() => onSelect()}>
      {suggestion.profilePic && (
        <img
          loading="lazy"
          src={suggestion.profilePic}
          alt={`Portrait of ${suggestion.name}`}
          className={styles.portrait}
        />
      )}
      {getHighlightedText(displayText, searchTerm)}
    </li>
  );
}
