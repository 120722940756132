export const DUMMY_EVENTS_LIST = [
  {
    name: "Toronto Festival",
    eid: 1,
    promoter: 1,
    venue: "Rogers Centre",
    performance: "Music",
  },
  {
    name: "Pop Music",
    eid: 2,
    promoter: 2,
    venue: "Scotiabank Arena",
    performance: "Live performance",
  },
  {
    name: "Latin Dance",
    eid: 3,
    promoter: 3,
    venue: "Budweiser Stage",
    performance: "Dance",
  },
  {
    name: "Taylor Listening",
    eid: 4,
    promoter: 3,
    venue: "CNE Bandshell",
    performance: "Live performance",
  },
  {
    name: "Jazz Music",
    eid: 5,
    promoter: 4,
    venue: "Coca-Cola Coliseum",
    performance: "Dance",
  },
  {
    name: "Kpop Music",
    eid: 6,
    promoter: 4,
    venue: "Scotiabank Arena",
    performance: "Dance",
  },
  {
    name: "Emo songs",
    eid: 7,
    promoter: 5,
    venue: "Rogers Centre",
    performance: "Dance",
  },
  {
    name: "Orchestra",
    eid: 8,
    promoter: 5,
    venue: "Budweiser Stage",
    performance: "Dance",
  },
];
