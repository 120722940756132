import CTAEdit from "./CTAEdit";

import SectionWrapper from "../../../UI/Layout/SectionWrapper";

import styles from "./CallToAction.module.scss";

export default function CallToActionSection({ CTA, onUpdate }) {
  return (
    <SectionWrapper
      title="Call-to-Action sections"
      description="You can change the content of the Call-To-Action sections here"
    >
      <div className={styles["card-container"]}>
        <CTAEdit
          title="EVENTS"
          category="events"
          content={CTA.events}
          onUpdate={onUpdate}
        />
        <CTAEdit
          title="PROMOTERS"
          category="promoters"
          content={CTA.promoters}
          onUpdate={onUpdate}
        />
      </div>
    </SectionWrapper>
  );
}
