import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import SectionWrapper from "../../UI/Layout/SectionWrapper";
import Button from "../../UI/Buttons/Button";

import styles from "./HeroImage.module.scss";

function HeroImage({ image, onUpdate }) {
  const [heroImage, setHeroImage] = useState(image);

  useEffect(() => {
    setHeroImage(image);
  }, [image]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setHeroImage(e.target.result);
      onUpdate((prev) => ({ ...prev, HERO: e.target.result }));
    };

    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });

  const buttonClickHandler = () => {
    document.getElementById("fileInput").click();
  };

  const fileChangeHandler = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setHeroImage(e.target.result);
      onUpdate((prev) => ({ ...prev, HERO: e.target.result }));
    };

    reader.readAsDataURL(file);
  };

  return (
    <SectionWrapper
      title="Hero Image"
      description="You can change the hero image here"
    >
      <div
        {...getRootProps()}
        className={`${styles["hero-image-container"]} ${
          isDragActive ? styles["drag-active"] : ""
        }`}
        style={{
          backgroundImage: `url(${heroImage})`,
          filter: isDragActive ? "grayscale(0.8)" : "",
        }}
      >
        <input {...getInputProps()} />
        <div className={styles["hero-content"]}>
          <p className={styles["hero-text"]}>
            {isDragActive ? "Drop it!" : "Drag and drop an image or"}
          </p>
          {!isDragActive && (
            <>
              <Button
                className={styles["change-image-button"]}
                onClick={buttonClickHandler}
              >
                Upload Image
              </Button>
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={fileChangeHandler}
                style={{ display: "none" }}
              />
            </>
          )}
        </div>
      </div>
    </SectionWrapper>
  );
}

export default HeroImage;
